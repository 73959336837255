html {
  max-width: 70ch;
  margin: auto;
  padding: 3em 1em;
  font-family: Arial, Helvetica, sans-serif;
  font-size: 1.25em;
  line-height: 1.75;
}

/*# sourceMappingURL=index.b0047e18.css.map */
